<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="科目名称">
                <a-input v-model="queryParam.subject_name" placeholder="根据科目名称查询" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="部门">
                <a-select showSearch allowClear style="width: 100%" v-model="queryParam['department']">
                  <a-select-option v-for="op in GLOBAL.departmentMaps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="query" icon="search" @click="queryList">查询</a-button>
        <a-button type="primary" icon="plus" @click="handleCreate">新增</a-button>
        <a-button type="danger" icon="delete" @click="handleDelete" :disabled="!selectedRowKeys.length">删除</a-button>
      </div>

      <a-table
        size="small"
        row-key="id"
        bordered
        :scroll="{ x: 'max-content' }"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="handleChangeTable"
        :row-selection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
      >
        <template slot="action" slot-scope="text, record">
          <a @click="handleUpdate(record)">编辑</a>
        </template>
      </a-table>

      <a-modal
        :visible="visible"
        :confirmLoading="loading"
        :width="800"
        :maskClosable="false"
        :title="title"
        @ok="handleSubmit"
        @cancel="handleCancel"
      >
        <a-form-model
          layout="vertical"
          ref="form"
          :model="form"
          :rules="rules"
        >
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-model-item label="科目编码" prop="subject_code">
                <a-input v-model.trim="form.subject_code" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="科目名称" prop="subject_name">
                <a-input v-model.trim="form.subject_name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="部门">
                <a-select mode="multiple" allowClear placeholder="请选择部门" v-model="form.department">
                  <a-select-option v-for="item in GLOBAL.departmentMaps" :key="item.value">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="科目规格">
                <a-input v-model.trim="form.subject_spec" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="计量单位">
                <a-input v-model.trim="form.subject_unit" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="税率" prop="tax_rate">
                <a-input v-model="form.tax_rate" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-model-item label="备注" prop="remark">
                <a-textarea v-model.trim="form.remark" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import {
  pageInvoiceSubject,
  // listInvoiceSubjectTree,
  saveOrUpdateInvoiceSubject,
  deleteInvoiceSubject
} from '@/api/fms'

export default {
  name: 'FmsInvoiceSubject',
  data() {
    return {
      queryParam: {},
      // invoiceSubjectData: [],
      selectedRowKeys: [],
      selectedRows: [],
      columns: [
        {
          title: '科目编号',
          dataIndex: 'subject_code'
        },
        {
          title: '科目名称',
          dataIndex: 'subject_name'
        },
        {
          title: '科目规格',
          dataIndex: 'subject_spec'
        },
        {
          title: '计量单位',
          dataIndex: 'subject_unit'
        },
        {
          title: '税率',
          dataIndex: 'tax_rate'
        },
        {
          title: '部门',
          dataIndex: 'department_names'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '50'],
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      visible: false,
      loading: false,
      title: null,
      form: {
        subject_code: null,
        subject_name: null,
        subject_spec: null,
        subject_unit: null,
        parent_id: null,
        tax_rate: null,
        remark: null,
        department:[]
      },
      rules: {
        subject_code: [{ required: true, message: '请输入科目编号', trigger: 'blur' }],
        subject_name: [{ required: true, message: '请输入科目名称', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      pageInvoiceSubject(this.queryParam).then(res => {
        this.data = res.list
        this.pagination.total = res.total
      })
    },
    queryList() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.queryParam['page'] = 1
      this.queryParam['page_size'] = 10
      this.loadData()
    },
    handleChangeTable(page) {
      this.pagination.current = page.current
      this.pagination.pageSize = page.pageSize
      this.queryParam['page'] = page.current
      this.queryParam['page_size'] = page.pageSize
      this.loadData()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    // listInvoiceSubjectTree() {
    //   listInvoiceSubjectTree().then(res => {
    //     const treeData = this.recursion(res, [])
    //     this.invoiceSubjectData = treeData
    //   })
    // },
    recursion(sourceData, arr) {
      sourceData.forEach((ele, index) => {
        arr.push({ 'title': ele.subject_name, 'value': ele.id, 'key': ele.id, 'children': [] })
        if (ele.children && ele.children.length > 0) {
          this.recursion(ele.children, arr[index]['children'])
        }
      })
      return arr
    },
    handleCreate() {
      this.visible = true
      this.title = '新增开票科目'
      // this.listInvoiceSubjectTree()
    },
    handleUpdate(record) {
      this.visible = true
      this.title = '修改开票科目'
      this.form = Object.assign({}, record)
      // this.listInvoiceSubjectTree()
    },
    handleSubmit() {
      this.loading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          saveOrUpdateInvoiceSubject(this.form).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            })
            this.handleCancel()
            this.loadData()
          })
        } else {
          this.loading = false
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.form = {
        subject_name: null,
        parent_id: null,
        tax_rate: null,
        remark: null
      }
    },
    handleDelete() {
      this.$confirm({
        title: '提示',
        content: '确认要删除吗?',
        onOk: () => {
          const idList = this.selectedRows.map(item => item.id)
          deleteInvoiceSubject({ ids: idList }).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功'
            })
            this.loadData()
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
